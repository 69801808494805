import { render, staticRenderFns } from "./WorkerDocument.vue?vue&type=template&id=49fbf99f&scoped=true&"
import script from "./WorkerDocument.vue?vue&type=script&setup=true&lang=ts&"
export * from "./WorkerDocument.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./WorkerDocument.vue?vue&type=style&index=0&id=49fbf99f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49fbf99f",
  null
  
)

export default component.exports